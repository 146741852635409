import dayjs from 'dayjs'

const AGREEMENT_COOKIE_KEY = 'agreement_cookie'

interface ICookieInfo {
  agreement: boolean
  expires: string
}

function removeAgreementInfoOld() {
  // Удаление записи из куков (старый метод)
  document.cookie = 'agreement=false; expires=Thu, 01 Jan 1970 00:00:00 GMT'
}

function removeAgreementInfo() {
  localStorage.removeItem(AGREEMENT_COOKIE_KEY)
}

export function useCookies() {
  function setAgreement(result: boolean) {
    const cookieInfo: ICookieInfo = {
      agreement: false,
      expires: '',
    }
    if (result) {
      cookieInfo.agreement = true
      cookieInfo.expires = dayjs().add(6, 'month').toString()
    }
    localStorage.setItem(AGREEMENT_COOKIE_KEY, JSON.stringify(cookieInfo))
  }

  function checkAgreement(): boolean {
    // Удалить старые куки
    removeAgreementInfoOld()

    let result = false
    const agreementInfo = localStorage.getItem(AGREEMENT_COOKIE_KEY)
    if (agreementInfo) {
      const { expires } = JSON.parse(agreementInfo)
      const now = dayjs()
      result = !now.isAfter(dayjs(expires))
    }

    if (!result) {
      removeAgreementInfo()
    }

    return result
  }

  return {
    setAgreement,
    checkAgreement,
  }
}
