<template>
  <div class="q-cookie-agreement">
    <div class="q-cookie-agreement__content">
      <h3>
        Соглашение об использовании файлов cookie
      </h3>
      <QCookieAgreementDescription v-if="showDescription" />
      <QCookieAgreementMainText v-else @open="showDescription = true" />
      <span class="q-cookie-agreement__content-action">
        <AButton type="primary" @click="onSubmit">
          Принять и продолжить
        </AButton>
        <AButton @click="onClose">
          Отклонить
        </AButton>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import QCookieAgreementMainText from '@/components/cookie-agreement/QCookieAgreementMainText.vue'
import QCookieAgreementDescription from '@/components/cookie-agreement/QCookieAgreementDescription.vue'
import { useCookies } from '@/composables/useCookies'

const emits = defineEmits(['close'])

const showDescription = ref(false)

function onSubmit() {
  useCookies().setAgreement(true)
  emits('close')
}

function onClose() {
  useCookies().setAgreement(false)
  emits('close')
}
</script>

<style lang="sass" scoped>
@import './QCookieAgreement'
</style>
