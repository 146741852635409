import type { Module } from 'vuex'
import { message } from 'ant-design-vue'
import type { IRootStore } from '../interfaces'
import type { IAuthState } from './interfaces'
import AuthAPI from '@/api/AuthAPI'
import router from '@/router'
import ContragentService from '@/services/main/ContragentService'

const auth: Module<IAuthState, IRootStore> = {
  state: {
    user: JSON.parse(localStorage.getItem('user') as string),
    error: null,
    auth: Boolean(localStorage.getItem('auth')) || false,
    token: localStorage.getItem('token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
    expires_in: localStorage.getItem('expires_in') || null,
    marketplace_token: localStorage.getItem('marketplace_token') || null,
  },

  getters: {
    isAuthenticated: state => state.auth,
    getUser: ({ user }) => user,
    getToken: ({ token }) => token,
    isClosedAccount: ({ user }) => user.isClosed ?? false,
    getError: state => state.error,
    isMarketplaceToken: state => !!state.marketplace_token,
  },

  mutations: {
    setToken(state, data) {
      state.token = data?.access_token
      state.refresh_token = data?.refresh_token
      state.expires_in = data?.expires_in
      localStorage.setItem('token', data?.access_token as string)
      localStorage.setItem('refresh_token', data?.refresh_token as string)
      localStorage.setItem('expires_in', data?.expires_in as string)
      if (data?.marketplace_token) {
        state.marketplace_token = data?.marketplace_token
        localStorage.setItem('marketplace_token', data?.marketplace_token as string)
      }
    },

    discardToken(state) {
      state.token = null
      state.refresh_token = null
      state.expires_in = null
      state.marketplace_token = null
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('expires_in')
      localStorage.removeItem('marketplace_token')
      localStorage.removeItem('auth')
    },

    setAuth(state, boolean) {
      state.auth = boolean
      localStorage.setItem('auth', boolean as unknown as string)
    },

    setUser(state, data) {
      state.user = data
      if (!data) {
        localStorage.removeItem('user')
      }
      else {
        localStorage.setItem('user', JSON.stringify(data))
      }
    },

    updateUser(state, data) {
      if (state.user.fullName !== data.fullName) {
        state.user.fullName = data.fullName
        state.user.firstName = data.firstName
        state.user.lastName = data.lastName
        state.user.middleName = data.middleName
        localStorage.setItem('user', JSON.stringify(state.user))
      }
    },

    setError(state, error) {
      state.error = error
    },
  },

  actions: {
    async onLogin({ dispatch, commit }, user) {
      await AuthAPI.login(user).then((res) => {
        commit('setToken', res.data)
        commit('setAuth', true)
        dispatch('getContragent')
      })
    },

    async getContragent({ dispatch, commit }) {
      await ContragentService.getUser().then(async (res) => {
        if (res.data.contragents.length) {
          await ContragentService.getContragent(res.data.contragents[0].id).then((response) => {
            const user = {
              ...response.data,
              lastName: res.data.lastName,
              firstName: res.data.firstName,
              middleName: res.data.middleName,
              userId: res.data.id,
            }
            commit('setUser', user)
            router.push({ name: 'Главный экран', params: { userId: user.id } })
          })
        }
        else {
          message.error('Лицевой счет не найден')
          dispatch('onLogout')
        }
      })
    },

    async onRefreshToken({ commit, dispatch }) {
      await AuthAPI.refreshToken().then((res) => {
        commit('setToken', res.data)
        dispatch('getContragent')
      })
    },

    async onLogout({ commit }) {
      AuthAPI.revokeToken()
        .catch(err => message.error({ content: err?.response?.data?.details }))
      commit('setAuth', false)
      commit('discardToken')
      commit('setUser', null)
      await router.push('/login')
    },

    async globalError({ commit }, error) {
      commit('setError', error)
    },
  },
}

export default auth
